@tailwind base;
@tailwind components;
@tailwind utilities;

.roboto-mono-200{
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.ubuntu{
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.play-regular {
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-200 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.App {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;


  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: calc(10px + 2vmin);

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
